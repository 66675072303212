<template>
  <button v-if="type" class='product-button' @click="handleButtonClick">
    <img :src="logo">
  </button>
</template>

<script>
export default {
  name: 'ProductButton',
  props: {
    type: {
      type: String,
      validator: (val) => ['bitTop20', 'bitAtlas'].indexOf(val) !== -1
    }
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {
    logo() {
      switch (this.type) {
        case 'bitTop20':
          return '/images/logos/bit20-logo.svg'
        case 'bitAtlas':
          return '/images/logos/bitatlas-logo.svg'
        case 'bit5':
          return '/images/logos/bit5-logo.svg'
        case 'greyBit5':
          return '/images/logos/bit5-logo-grey.svg'
        case 'greyBit20':
          return '/images/logos/bit20-logo-grey.svg'
        default:
          return undefined;
      }
    }
  },
  methods: {
    handleButtonClick() {
      switch (this.type) {
        case 'bitTop20':
          return this.$router.push('/products/bit20')
        case 'bitAtlas':
          return this.$router.push('/products/bitAtlas')
        case 'bit5':
          return this.$router.push('/products/bit5')
        case 'greyBit5':
          return this.$router.push('/products/bit5')
        case 'greyBit20':
          return this.$router.push('/products/bit20')
        default:
          return undefined;
      }
    }
  }
}
</script>
 
<style lang='scss' scoped>
button.product-button {
  appearance: none;
  border: 1px solid $light-purple;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-global;
  padding: 11px 32px;
  cursor: pointer;
  transition: $transition-global;

  &:hover {
    border-color: $primary-purple;
  }

  img {
    max-height: 45px;
    width: auto;
  }
}
</style>