<template>
  <div class="reports-section" :style="`--backgroundColor: ${backgroundColor}`">
    <div class="container">
      <h1 class="section-title headline-l">{{ reportTitle }}</h1>
      <div class="row buttons-row">
        <div class="col">
          <h3 class="subtitle headline-l" v-html="reportSubtitle" />
          <base-button :size="'large'" :text="reportButtonTitle" @clicked="handleReportBtnClick" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportSection',
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: "#F6F4FF"
    },
    reportTitle: {
      type: String,
      required: true,
    },
    reportSubtitle: {
      type: String,
      required: true,
    },
    reportButtonTitle: {
      type: String,
      required: true,
    },
    reportLink: {
      type: String,
      required: true,
    }
  },
  methods: {
    handleReportBtnClick() {
      window.open(this.reportLink, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
.reports-section {
  background-color: var(--backgroundColor);
  padding: 120px 0 90px;

  .subtitle {
    font-size: 30px;
    line-height: 1.2;
    text-align: center;
    margin: 0 auto 30px;

    @media (max-width: $breakpoint-md) {
      max-width: none;
    }

    @media (max-width: $breakpoint-xs) {
      font-size: 24px;
    }
  }

  .buttons-row {
    .col {
      text-align: center;
    }
  }
}

.section-title {
  margin: 0 0 70px;
  text-align: center;

  @media (max-width: $breakpoint-sm) {
    text-align: center;
    padding: 0 15px;
  }
}
</style>