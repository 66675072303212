<template>
  <div class="bitfamily-section">
    <div class="container">
      <div class="row">
        <div class="col-md-5 logo-col">
          <img v-if="imgSrc" :src="imgSrc" class="d-none d-sm-block">
          <img v-if="imgSrcMobile" :src="imgSrcMobile" class="d-sm-none no-padding" >
        </div>
        <div class="col-md-7 content-col">
          <h1 class="title headline-l">{{ title }}</h1>
          <p class="description text-l" v-html="description" />
          <div class="informations-wrapper">
            <span class="label text-bold">{{ buttonsLabel }}</span>
            <div class="buttons-row">
              <base-button
                v-if="lightPaperButton"
                :text="lightPaperButton.text"
                size="large"
                @clicked="handleButtonClick(lightPaperButton.link)"
              />
              <base-button
                v-if="productDescButton"
                :text="productDescButton.text"
                size="large"
                @clicked="handleButtonClick(productDescButton.link)"
              />
            </div>
          </div>
          <div class="more-wrapper">
            <span class="label text-bold">{{ moreLabel }}</span>
            <div class="product-buttons-row">
              <product-button :type="'bitAtlas'" />
              <product-button :type="'greyBit20'" />
              <product-button :type="'greyBit5'" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductButton from '@/components/sections/ProductButton';

export default {
  name: 'BitFamilySection',
  props: {
    imgSrc: {
      type: String,
      required: false,
      default: '/images/sections/bit/desktop/bitfamily.jpg'
    },
    imgSrcMobile: {
      type: String,
      required: false,
      default: '/images/sections/bit/mobile/bitfamily.jpg'
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    buttonsLabel: {
      type: String,
      required: true
    },
    lightPaperButton: {
      type: Object,
      required: false
    },
    productDescButton: {
      type: Object,
      required: false
    },
    moreLabel: {
      type: String,
      required: true
    },
  },
  components: {
    ProductButton
  },
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {
    handleButtonClick(link) {
      window.open(link, '_blank');
    }
  }
}
</script>
 
<style lang='scss' scoped>
.bitfamily-section {
  padding: 125px 0;

  @media (max-width: $breakpoint-sm) {
    padding: 0 0 60px;
  }
}

.logo-col {
  img {
    margin: 0 auto;
    max-width: 100%;
    height: auto;

    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  @media (max-width: $breakpoint-sm) {
    padding: 0 0 30px;
  }
}

.content-col {
  padding-left: 130px;

  @media (max-width: $breakpoint-md) {
    padding-left: 20px;
  }
}

h1.title {
  margin: 0 0 45px;

  @media (max-width: $breakpoint-sm) {
    text-align: center;
  }
}

p.description {
  margin: 0 0 25px;

  @media (max-width: $breakpoint-sm) {
    text-align: center;
  }
}

span.label {
  color: $text-blue;
  display: block;
  line-height: 24px;
  margin: 0 0 20px;
}

.informations-wrapper {
  margin: 0 0 30px;

  @media (max-width: $breakpoint-sm) {
    text-align: center;
  }
}

.more-wrapper {
  @media (max-width: $breakpoint-sm) {
    text-align: center;
  }
}

.buttons-row {
  button:first-child {
    margin: 0 20px 0 0;
  }
}

.product-buttons-row {
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-sm) {
    justify-content: center;
  }
  
  button {
    margin: 0 18px 0 0;

    &:last-child {
      margin: 0;
    }
  }
}
</style>