<template>
  <div class="details-section" :style="`--backgroundColor: ${backgroundColor}`">
    <section v-if="divisions" class="division">
      <div class="container">
        <h1 v-if="divisionTitle" class="section-title headline-l">{{ divisionTitle }}</h1>
        <div class="row">
          <div v-for="(item, i) in divisions" :key="i" class="col" :class="[divisions.length == 1 ? 'col-lg-9' : '', divisions.length > 2 ? 'col-12 col-md-3' : 'col-12']">
            <bordered-box
              :title="$t(item.value)"
              :borderColor="boxOptions.borderColor"
              :borderWidth="boxOptions.borderWidth"
              :boxShadow="boxOptions.boxShadow"
              :hasBorderAround="boxOptions.hasBorderAround"
              :background="boxOptions.background"
            >
              <template v-slot:description>
                <span class="description text-l" v-html="$t(item.description)" />
              </template>
            </bordered-box>
          </div>
        </div>
      </div>
    </section>
    <section v-if="fees" class="fees" :class="{'has-row-gap': fees.length >= 3}">
      <div class="container">
        <h1 v-if="feesTitle" class="section-title headline-l">{{ feesTitle }}</h1>
        <div class="row">
          <div v-for="(part, i) in fees" :key="i" class="col col-12 col-md-6">
            <bordered-box
              :title="$t(part.title)"
              :borderColor="boxOptions.borderColor"
              :borderWidth="boxOptions.borderWidth"
              :boxShadow="boxOptions.boxShadow"
              :hasBorderAround="boxOptions.hasBorderAround"
              :background="boxOptions.background"
            >
              <template v-slot:description>
                <ul>
                  <li v-for="(item, index) in part.values" :key="index">
                    <span class="value text-l" v-html="$t(item.value)" />
                    <span class="label text-l">
                      <span class="prefix" v-if="part.values.length > 1">-</span> <span v-html="$t(item.label)" />
                    </span>
                  </li>
                </ul>
              </template>
            </bordered-box>
          </div>
          <div v-if="feesInfoTexts" class="informations-wrapper col-12">
            <span class="info-text text-l" v-for="(item, i) in feesInfoTexts" :key="i" v-html="$t(item)" />
          </div>
        </div>
      </div>
    </section>
    <section v-if="offeredPlans" class="fees" :class="{'has-row-gap': offeredPlans.length >= 3}">
      <div class="container">
        <h1 v-if="offeredPlansTitle" class="section-title headline-l">{{ offeredPlansTitle }}</h1>
        <div class="row">
          <div v-for="(list, i) in offeredPlans" :key="i" class="col col-12 col-md-6">
            <bordered-box
              :title="$t(list.title)"
              :borderColor="boxOptions.borderColor"
              :borderWidth="boxOptions.borderWidth"
              :boxShadow="boxOptions.boxShadow"
              :hasBorderAround="boxOptions.hasBorderAround"
              :background="boxOptions.background"
            >
              <template v-slot:description>
                <ul>
                  <li v-for="(item, index) in list.values" :key="index">
                    <span class="value text-l" v-html="$t(item.value)" />
                    <span class="label text-l">
                      <span class="prefix" v-if="list.values.length > 1">-</span> <span v-html="$t(item.label)" />
                    </span>
                  </li>
                </ul>
              </template>
            </bordered-box>
          </div>
          <div v-if="offeredPlansInfoText" class="informations-wrapper col-12">
            <span class="info-text text-l" v-for="(item, i) in offeredPlansInfoText" :key="i" v-html="$t(item)" />
          </div>
        </div>
      </div>
    </section>
    <section v-if="limitations" class="fees" :class="{'has-row-gap': limitations.length >= 3}">
      <div class="container">
        <h1 v-if="limitationsTitle" class="section-title headline-l">{{ limitationsTitle }}</h1>
        <div class="row">
          <div v-for="(part, i) in limitations" :key="i" class="col col-12 col-md-6">
            <bordered-box
              :title="$t(part.title)"
              :borderColor="boxOptions.borderColor"
              :borderWidth="boxOptions.borderWidth"
              :boxShadow="boxOptions.boxShadow"
              :hasBorderAround="boxOptions.hasBorderAround"
              :background="boxOptions.background"
            >
              <template v-slot:description>
                <ul>
                  <li v-for="(item, index) in part.values" :key="index">
                    <span class="value text-l" v-html="$t(item.value)" />
                    <span class="label text-l">
                      <span class="prefix" v-if="part.values.length > 1">-</span> <span v-html="$t(item.label)" />
                    </span>
                  </li>
                </ul>
              </template>
            </bordered-box>
          </div>
          <div v-if="limitationsInfoTexts" class="informations-wrapper col-12">
            <span class="info-text text-l" v-for="(item, i) in limitationsInfoTexts" :key="i" v-html="$t(item)" />
          </div>
        </div>
      </div>
    </section>
    <section v-if="faq" class="fees" :class="{'has-row-gap': faq.length >= 3}">
      <div class="container">
        <h1 v-if="faqTitle" class="section-title headline-l">{{ faqTitle }}</h1>
        <div class="row">
          <div v-for="(part, i) in faq" :key="i" class="col col-12 col-md-6">
            <bordered-box
              :title="$t(part.title)"
              :borderColor="boxOptions.borderColor"
              :borderWidth="boxOptions.borderWidth"
              :boxShadow="boxOptions.boxShadow"
              :hasBorderAround="boxOptions.hasBorderAround"
              :background="boxOptions.background"
            >
              <template v-slot:description>
                <ul>
                  <li v-for="(item, index) in part.values" :key="index">
                    <span class="value text-l" v-html="$t(item.value)" />
                    <span class="label text-l">
                      <span class="prefix" v-if="part.values.length > 1">-</span> <span v-html="$t(item.label)" />
                    </span>
                  </li>
                </ul>
              </template>
            </bordered-box>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="reports">
      <div class="container">
        <h1 class="section-title headline-l">{{ reportTitle }}</h1>
        <div class="row buttons-row">
          <div class="col">
            <h3 class="subtitle headline-l" v-html="reportSubtitle" />
            <base-button :size="'large'" :text="reportButtonTitle" @clicked="handleReportBtnClick" />
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
import BorderedBox from '@/components/elements/BorderedBox';
import BaseButton from '@/components/elements/Button';

export default {
  name: "DetailsSection",
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: "#F6F4FF"
    },
    boxOptions: {
      type: Object,
      required: true,
    },
    divisionTitle: {
      type: String
    },
    divisions: {
      type: Array,
      required: false
    },
    feesTitle: {
      type: String
    },
    fees: {
      type: Array,
      required: false
    },
    faqTitle: {
      type: String
    },
    faq: {
      type: Array,
      required: false
    },
    feesInfoTexts: {
      type: Array,
      required: false
    },
    limitationsTitle: {
      type: String
    },
    offeredPlansTitle: {
      type: String
    },
    offeredPlans: {
      type: Array,
      required: false
    },
    offeredPlansInfoText: {
      type: Array,
      required: false
    },
    limitations: {
      type: Array,
      required: false
    },
    limitationsInfoTexts: {
      type: Array,
      required: false
    }
    // reportTitle: {
    //   type: String,
    //   required: true,
    // },
    // reportSubtitle: {
    //   type: String,
    //   required: true,
    // },
    // reportButtonTitle: {
    //   type: String,
    //   required: true,
    // },
    // reportLink: {
    //   type: String,
    //   required: true,
    // }
  },
  components: {
    BorderedBox,
    BaseButton
  },
  data: () => ({
    divisionPartKeys: [
      "significantPotential",
      "ebso",
      "goldBased",
      "mostValuable"
    ],
    feesPartKeys: [
      {
        part: "buying",
        listValues: [
          "sales",
          "treatmentFee",
        ]
      },
      {
        part: "sale",
        listValues: [
          "treatmentFee",
          "bsoPool"
        ]
      }
    ]
  }),
  created() {},
  computed: {},
  methods: {
    // handleReportBtnClick() {
    //   window.open(this.reportLink, '_blank');
    // }
  }
}
</script>
 
<style lang='scss' scoped>
.details-section {
  background-color: var(--backgroundColor);
}

.section-title {
  margin: 0 0 70px;

  @media (max-width: $breakpoint-sm) {
    text-align: center;
    padding: 0 15px;
  }
}

.bordered-box {
  height: 100%;
}

section.division {
  padding: 0 0 60px;

  .col {
    @media (max-width: $breakpoint-xxs) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    @media (max-width: $breakpoint-md) {
      margin-bottom: 30px;
    }
  }

  .bordered-box {
    &::v-deep {
      .description {
        @media (max-width: $breakpoint-md) {
          line-height: 24px;
        }

        p {
          margin: 0 0 30px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}

section.fees {
  padding: 0 0 130px;

  @media (max-width: $breakpoint-sm) {
    padding: 0 0 65px;
  }

  .col {
    @media (max-width: $breakpoint-sm) {
      margin: 0 0 40px;
    }
  }
  
  .bordered-box {
    &::v-deep {
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
      }

      .value {
        font-family: "HKNova-Bold", sans-serif;
      }
    }
  }

  &.has-row-gap .row {
    row-gap: 50px;

    .info-text {
      &:first-child {
        padding: 0;
      }
    }
  }
}

section.limits{
  padding: 0 0 130px;
}

// section.reports {
//   padding: 0 0 140px;

//   .subtitle {
//     font-size: 30px;
//     line-height: 1.2;
//     text-align: center;
//     margin: 0 auto 30px;

//     @media (max-width: $breakpoint-md) {
//       max-width: none;
//     }
//   }

//   .buttons-row {
//     .col {
//       text-align: center;
//     }
//   }
// }

.informations-wrapper {
  .info-text {
    display: inline-block;
    padding: 0 0 5px;

    &:last-child {
      padding: 0;
    }

    &:first-child {
      padding: 25px 0 0;

      @media (max-width: $breakpoint-sm) {
        padding: 0;
      }
    }
  }
}
.purchase-row{
  .title{
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    color:#262A52
  }
  .box-description{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }
  .list{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      font-weight: 500;
      color:#46668F
    }
  }
}
.note-row{
    max-width: 796px;
    margin: 20px 0;
    .list{
      list-style-type: "*";
    }
    .list-2{
      list-style-type: "**";
    }
    li{
      padding-left: 15px;
      padding-top: 10px;
    }
    .note {
      font-size: 16px;
      line-height: 24px;
      color: #46668F;
      padding: 0 25px;
    }
}
</style>