<template>
  <div class="advantages-section" :style="`--backgroundColor: ${backgroundColor}`">
    <section v-if="introduction" class="introduction">
      <div class="container">
        <div class="row">
          <div class="col">
            <img v-if="introduction.logoSrc" :src="introduction.logoSrc" class="logo">
            <span v-if="introduction.description" class="description text-l">{{ introduction.description }}</span>
          </div>
        </div>
      </div>
    </section>
    <section class="advantages">
      <div class="container">
        <h1 class="section-title headline-l">{{ advantagesTitle }}</h1>
        <div class="row">
          <div v-for="(item, i) in advantages" :key="i" class="col-6 col-sm-4 col-md-3" :class="{ 'col-12' : item.descriptionKey }">
            <div class="advantage">
              <div class="icon-wrapper">
                <img class="icon" :src="item.iconUrl">
              </div>
              <span class="label text-l">{{ $t(item.labelKey) }}</span>
              <span v-if="item.descriptionKey" class="description text-l" v-html="$t(item.descriptionKey)" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AdvantagesSection",
  props: {
    introduction: {
      type: Object,
      required: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#F6F4FF"
    },
    advantagesTitle: {
      type: String,
      required: true
    },
    advantages: {
      type: Array,
      required: true
    }
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.advantages-section {
  background-color: var(--backgroundColor);
  padding: 100px 0 0;
  margin: 80px 0 0;
}

section.introduction {
  margin: 0 0 70px;

  .logo {
    margin: 0 0 30px;

    @media (max-width: $breakpoint-sm) {
      max-height: 75px;
    }
  }

  .description {
    max-width: 730px;
    display: block;
  }
}

.section-title {
  margin: 0 0 100px;

  @media (max-width: $breakpoint-xs) {
    text-align: center;
  }
}

.advantage {
  margin: 0 0 80px;

  @media (max-width: $breakpoint-xs) {
    text-align: center;
  }

  .label {
    margin: 10px 0 0;
    display: inline-block;
    padding-right: 40px;
    font-family: 'HKNova-Bold', sans-serif;
    line-height: 24px;

    @media (max-width: $breakpoint-lg) {
      padding-right: 0;
    }
  }

  .description {
    display: inline-block;
    line-height: 24px;
    padding: 24px 0 0;

    &::v-deep {
      span {
        margin: 35px 0 0;
        display: block;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  .icon-wrapper {
    min-height: 42px;
    display: flex;
    align-items: center;

    img {
      max-height: 42px;

      @media (max-width: $breakpoint-xs) {
        max-height: 33px;
      }
    }

    @media (max-width: $breakpoint-xs) {
      min-height: 32px;
      width: 100%;
      justify-content: center;
    }
  }
}
</style>