<template>
  <div class="operation-section">
    <div class="container">
      <h1 v-if="title" class="section-title headline-l">{{ title }}</h1>
      <div class="row">
        <div v-for="(item, i) in parts" :key="i" class="box-col col-12 col-md-4">
          <div class="box-wrapper">
            <img class="illustration" :src="item.illustrationUrl">
            <h3 class="title headline">{{ $t(item.titleKey) }}</h3>
            <span class="description text-l">{{ $t(item.descriptionKey) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OperationSection",
  props: {
    title: {
      type: String
    },
    parts: {
      type: Array,
      required: true,
    }
  },
  components: {},
  data: () => ({
    // parts: [
    //   {
    //     illustrationUrl: "/images/sections/bit/vasarlas.svg",
    //     titleKey: "bitScreen.operation.parts.shopping.title",
    //     descriptionKey: "bitScreen.operation.parts.shopping.description"
    //   },
    //   {
    //     illustrationUrl: "/images/sections/bit/kezeles.svg",
    //     titleKey: "bitScreen.operation.parts.handling.title",
    //     descriptionKey: "bitScreen.operation.parts.handling.description"
    //   },
    //   {
    //     illustrationUrl: "/images/sections/bit/reszesedes.svg",
    //     titleKey: "bitScreen.operation.parts.sharing.title",
    //     descriptionKey: "bitScreen.operation.parts.sharing.description"
    //   },
    // ]
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.section-title {
  margin: 0 0 55px;

  @media (max-width: $breakpoint-sm) {
    margin: 0 0 40px;
    text-align: center;
  }
}

.box-col {
  @media (max-width: $breakpoint-sm) {
    margin: 0 0 40px;

    &:last-child {
      margin: 0;
    }
  }
}

.box-wrapper {
  .title {
    margin: 20px 0;

    @media (max-width: $breakpoint-sm) {
      text-align: center;
    }
  }

  .description {
    color: $text-blue;

    @media (max-width: $breakpoint-sm) {
      text-align: center;
      display: block;
    }
  }

  .illustration {
    width: 100%;
    height: auto;
  }
}
</style>