<template>
  <div class="download-section" :style="`--backgroundColor: ${backgroundColor}`">
    <div class="container">
      <div v-if="logoSrc" class="section-logo">
        <img :src="logoSrc" >
      </div>
      <h1 class="section-title headline-l">{{ title }}</h1>
      
      <!-- TODO: remove this section if not needed -->
      <div class="qr-code-wrapper">
        <span v-if="buttonsLabel" class="label text-l">{{ buttonsLabel }}</span>
        <div class="buttons-row">
          <base-button
            v-if="paperLink !== undefined"
            :text="$t('bit20Screen.download.paperButtonText')"
            size="large"
            @clicked="handleButtonClick('paper')"
          />
          <base-button
            v-if="productLink !== undefined"
            :text="$t('deltaScreen.download.productButtonText')"
            size="large"
            @clicked="handleButtonClick('product')"
          />
        </div>
      </div>
      <div class="apps-wrapper" v-if="download">
        <span class="label">{{ appsLabel }}</span>
        <div class="stores">
          <div class="row">
            <div class="col-6"><store-button :version="'apple'" /></div>
            <div class="col-6"><store-button :version="'google'" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DownloadSection",
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: "#F6F4FF"
    },
    logoSrc: {
      type: String
    },
    title: {
      type: String,
      required: true
    },
    download: {
      type: Boolean,
      required: false,
    },
    appsLabel: {
      type: String,
      required: true
    },
    buttonsLabel: {
      type: String,
      required: false
    },
    paperLink: {
      type: String,
      required: false
    },
    productLink: {
      type: String,
      required: false
    }
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {
    handleButtonClick(type) {
      if(type == "paper") {
        if(this.paperLink === null) {
          return undefined
        }
        window.open(this.paperLink, "_blank");
      } else if(type == "product") {
        if(this.productLink === null) {
          return undefined
        }
        window.open(this.productLink, "_blank");
      }
    }
  }
}
</script>
 
<style lang='scss' scoped>
.download-section {
  background: var(--backgroundColor);
  padding: 70px 0 95px;
}

.section-title {
  text-align: center;
  max-width: 730px;
  margin: 0 auto 55px;

  @media (max-width: $breakpoint-sm) {
    font-size: 30px;
    line-height: 35px;
    margin: 0 auto 65px;
  }
}

.section-logo {
  padding: 0 0 40px;

  img {
    margin: 0 auto;
    max-height: 85px;
    width: auto;
  }
}

.apps-wrapper {
  text-align: center;

  .label {
    font-size: 16px;
    line-height: 34px;
    font-family: "HKNova-Bold", sans-serif;
    margin: 0 0 30px;
    display: block;
    color: $text-blue;
  }

  .stores {
    .apple-button {
      margin-left: auto;
    }

    .google-button {
      margin-right: auto;
    }
  }
}

.qr-code-wrapper {
  margin: 55px 0 60px;
  text-align: center;

  .label {
    display: block;
    margin: 0 0 30px;
  }

  .qrcode {
    margin: 0 auto;
  }
}

.buttons-row {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    &:first-child {
      margin: 0 20px 0 0;
    }
  }
}
</style>